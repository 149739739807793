<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A molecule with a
        <span class="text-bold">{{ geometry }}</span> molecular geometry has
        <span class="text-underline text-bold">&nbsp;&nbsp;A&nbsp;&nbsp;</span>
        electron domains around the central atom. The central atom in this molecule would have
        <span class="text-underline text-bold">&nbsp;&nbsp;B&nbsp;&nbsp;</span>
        lone pairs and would be bonded to
        <span class="text-underline text-bold">&nbsp;&nbsp;C&nbsp;&nbsp;</span>
        atoms.
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{A:}$"
        append-text="$\text{electron domains}$"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.input2"
        prepend-text="$\text{B:}$"
        append-text="$\text{lone pairs}$"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.input3"
        prepend-text="$\text{C:}$"
        append-text="$\text{bonding domains}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';

const TRIGONAL_PLANAR = 1;
const TETRAHEDRAL = 2;
const TRIGONAL_PYRAMIDAL = 3;
const TRIGONAL_BIPYRAMIDAL = 4;
const SEESAW = 5;
const OCTAHEDRAL = 6;
const SQUARE_PYRAMIDAL = 7;
const SQUARE_PLANAR = 8;

export default {
  name: 'Question40',
  components: {
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
    };
  },
  computed: {
    geometryNumber() {
      return this.taskState.getValueBySymbol('geometryNumber').content.value;
    },
    geometry() {
      switch (this.geometryNumber) {
        case TRIGONAL_PLANAR:
          return 'trigonal planar';
        case TETRAHEDRAL:
          return 'tetrahedral';
        case TRIGONAL_PYRAMIDAL:
          return 'trigonal pyramidal';
        case TRIGONAL_BIPYRAMIDAL:
          return 'trigonal bipyramidal';
        case SEESAW:
          return 'see-saw';
        case OCTAHEDRAL:
          return 'octahedral';
        case SQUARE_PYRAMIDAL:
          return 'square pyramidal';
        case SQUARE_PLANAR:
          return 'square planar';
        default:
          return 'error';
      }
    },
  },
};
</script>
